import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">
        Moving Beyond Role-Based Access Control: The Future of Cloud-Based Application Security
      </div>
      <p className="text-lg text-left">
        <b>Understanding the Downfalls of RBAC and the Rise of Dynamic Access Permissions</b>
        <br></br>
        <br></br>
        In the early days of cloud computing, many businesses relied on role-based access control (RBAC) as a means of
        managing access to cloud-based applications. RBAC systems defined roles, such as administrator or user, and
        granted permissions based on those roles. However, as cloud-based applications have become more complex and the
        threat landscape has evolved, RBAC has become less effective and less secure. In this article, Let’s explore the
        weaknesses of RBAC and the rise of dynamic access permissions as the future of enterprise security and
        compliance.
      </p>
      <br></br>
      <br></br>
      <p className="text-lg text-left">
        <b>What is Role-Based Access Control?</b>
        <br></br>
        <br></br>Role-based access control (RBAC) is a security model that restricts access to computer resources based
        on the roles assigned to individual users within an organization. An RBAC system grants permissions based on the
        user's job function or title, rather than the individual's identity. For example, an administrator might have
        permission to access all functions of a cloud-based application, while a regular user might only have permission
        to view and edit their own data.
      </p>
      <br></br>
      <br></br>
      <p className="text-lg text-left">
        <b>The Weaknesses of RBAC</b>
        <br></br>
        <br></br>
        While RBAC can be effective in simple situations, it has a number of drawbacks that make it less effective as
        cloud-based applications become more complex:
      </p>
      <br></br>
      <p className="text-lg text-left">
        <span className="italic font-bold">Limited Flexibility: </span> RBAC systems are rigid and inflexible, making it
        difficult to grant or revoke access to specific resources based on changing circumstances. If an individual
        needs access to a resource that falls outside of their role, an administrator must manually grant that
        permission, which can be time-consuming and error-prone. Increased Risk of Security Breaches: RBAC relies on
        predefined roles and permissions, which can become outdated or no longer relevant as applications evolve. This
        can leave sensitive resources vulnerable to exploitation by unauthorized users.<br></br>
        <br></br>
        <span className="italic font-bold">Lack of Granularity:</span> RBAC systems typically do not provide a granular
        level of access control. For example, a user might have access to all data within a particular database, even if
        they only need access to a subset of that data. <br></br>
        <br></br>
        <br></br>
        <b>Dynamic Access Permissions: The Future of Cloud-Based Application Security</b>
        <br></br>
        <br></br>
        To address the shortcomings of RBAC, many organizations are turning to dynamic access permissions. Unlike RBAC,
        which assigns permissions based on roles, dynamic access permissions use contextual information to determine
        whether to grant access to specific resources. This approach can take into account a range of factors, such as
        the user's location, device, and behavior, as well as the sensitivity of the resource being accessed.
      </p>
      <br></br>
      <br></br>
      <p className="text-lg text-left">
        <b>Dynamic access permissions provide a number of benefits over RBAC: </b>
        <br></br>
        <br></br>
        <span className="italic font-bold">Enhanced Flexibility:</span> Dynamic access permissions can adjust access
        permissions in real time based on changing circumstances, allowing organizations to maintain an appropriate
        level of security without relying on manual intervention.<br></br>
        <br></br>
        <span className="italic font-bold">Increased Security:</span> By evaluating a wider range of contextual
        information, dynamic access permissions are better able to identify and prevent unauthorized access to sensitive
        resources.<br></br>
        <br></br>
        <span className="italic font-bold">Greater Granularity:</span> Dynamic access permissions allow for a more
        granular level of access control, enabling organizations to grant access to only the resources that a user needs
        to do their job.<br></br>
        <br></br>
        <br></br> <b>Conclusion</b> <br></br>
        <br></br>While RBAC has served as a useful access control mechanism in the past, it has become less effective in
        the face of today's complex and evolving cloud-based applications. Dynamic access permissions offer a more
        effective and secure way to manage access to cloud-based resources, and are likely to become the norm in
        enterprise security and compliance. By leveraging the latest technologies and security frameworks, organizations
        can protect their sensitive data while still providing their users with the access they need to get their jobs
        done.<br></br>
        <br></br> Ready to move beyond RBAC and adopt dynamic access permissions for all of your cloud-based
        applications? Our team of security experts can help you explore best practices for enterprise security and
        compliance.{' '}
        <b>
          Ensuring you provide compliant, automated access for the right user, to the right resource, for the right
          length of time.
        </b>
      </p>
    </div>
  );
};

const RbacRoleBasedAccessControl = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `RBAC`;
  const description = `Role Based Access Control`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default RbacRoleBasedAccessControl;
